//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-556:_7736,_9288,_3735,_4804,_684,_6596,_8840,_8252;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-556')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-556', "_7736,_9288,_3735,_4804,_684,_6596,_8840,_8252");
        }
      }catch (ex) {
        console.error(ex);
      }