
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function MainContentRT () {
    return _createElement('div', {
        'id': 'itemsBlock',
        'className': this.responseState === 'nothing' || this.responseState === 'badRequest' ? 'cm_hide' : ''
    }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7050'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10350'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1568'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1982'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2236'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2554'
            }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t contain any\n    ',
                !!this.vehicleString && !this.notFilteredByVehicle ? [
                    '\n      products that fit a ',
                    _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                '\n    Click ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '32301'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3749'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3968'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '4212'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '43630' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '44500' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '45330' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '43284' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_mobile-hide cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'cm_button cm_button__primary cm_dialogButton' }, '\n  Filters ', [_createElement('svg', {
                                    'width': '15',
                                    'height': '9',
                                    'viewBox': '0 0 15 9',
                                    'fill': 'none',
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'key': '690'
                                }, _createElement('path', {
                                    'd': 'M13.25 1.5L7.625 7.5L2 1.5',
                                    'stroke': '#FF6800',
                                    'strokeWidth': '3',
                                    'strokeLinecap': 'round',
                                    'strokeLinejoin': 'round'
                                }))]);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', {
                'className': 'cm_total-hits',
                'role': 'status'
            }, _createElement('span', {}, this.totalHits, ' results')), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'SORT BY ', [_createElement('svg', {
                    'width': '15',
                    'height': '9',
                    'viewBox': '0 0 15 9',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'key': '12100'
                }, _createElement('path', {
                    'd': 'M13.25 1.5L7.625 7.5L2 1.5',
                    'stroke': '#FF6800',
                    'strokeWidth': '3',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round'
                }))]), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        return _createElement('div', {
                            'className': 'custom-product-grid-item product-grid-item',
                            'data-grid-item': true
                        }, _createElement('div', { 'className': 'product-grid-img' }, _createElement('a', {
                            'href': this.url,
                            'data-grid-link': true,
                            'className': 'lazy-image',
                            'aria-label': this.removeHTML(this.title)
                        }, _createElement('img', {
                            'src': this.imageOrDefault(this.resizeImage(this.image)),
                            'alt': this.removeHTML(this.title),
                            'onError': this.onImageError,
                            'className': 'lazyautosizes lazyloaded'
                        })), this.product_card_badge ? _createElement('div', mergeProps({
                            'className': 'cm_badge',
                            'key': '397'
                        }, { dangerouslySetInnerHTML: { __html: this.product_card_badge } })) : null), _createElement('div', { 'className': 'product__grid__info product__grid__info--under text-left' }, _createElement('div', {}, _createElement('div', { 'className': 'product__grid__title__wrapper' }, _createElement('div', { 'className': 'custom-product__grid__title' }, this.display_custom_title ? _createElement('div', mergeProps({
                            'className': 'meta-title',
                            'key': '728'
                        }, { dangerouslySetInnerHTML: { __html: this.display_custom_title } })) : null, !this.display_custom_title ? [
                            _createElement('div', mergeProps({
                                'className': 'vendor copy copy--medium',
                                'key': '8731'
                            }, { dangerouslySetInnerHTML: { __html: this.vendor } })),
                            _createElement('a', mergeProps({
                                'className': 'h6',
                                'href': this.url,
                                'key': '8733'
                            }, { dangerouslySetInnerHTML: { __html: this.title } }))
                        ] : null), this.stock === 'In Stock' ? _createElement('div', {
                            'className': 'stock-info in-stock copy copy--medium',
                            'key': '1188'
                        }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'fill': 'none',
                                'viewBox': '0 0 20 20',
                                'key': '12940'
                            }, _createElement('rect', {
                                'width': '18',
                                'height': '18',
                                'x': '1',
                                'y': '1',
                                'fill': '#48C11E',
                                'stroke': '#48C11E',
                                'strokeWidth': '2',
                                'rx': '9'
                            }), _createElement('path', {
                                'stroke': '#fff',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round',
                                'strokeWidth': '2',
                                'd': 'm6.155 10.585 2.354 2.353 6.015-6.015'
                            }))], '\n          In Stock\n        ') : null, this.stock === 'Low Stock' ? _createElement('div', {
                            'className': 'stock-info low-stock copy copy--medium',
                            'key': '1670'
                        }, [_createElement('svg', {
                                'width': '20',
                                'height': '20',
                                'viewBox': '0 0 20 20',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'key': '17780'
                            }, _createElement('rect', {
                                'x': '1',
                                'y': '1',
                                'width': '18',
                                'height': '18',
                                'rx': '9',
                                'fill': '#FF6800',
                                'stroke': '#FF6800',
                                'strokeWidth': '2'
                            }), _createElement('g', { 'clipPath': 'url(#clip0_828_47067)' }, _createElement('path', {
                                'd': 'M14.993 5.07788L4.99304 15.0779',
                                'stroke': 'white',
                                'strokeWidth': '2',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round'
                            }), _createElement('path', {
                                'd': 'M4.99304 5.07788L14.993 15.0779',
                                'stroke': 'white',
                                'strokeWidth': '2',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round'
                            })), _createElement('rect', {
                                'x': '1',
                                'y': '1',
                                'width': '18',
                                'height': '18',
                                'rx': '9',
                                'fill': '#FFB800',
                                'stroke': '#FFB800',
                                'strokeWidth': '2'
                            }), _createElement('g', { 'clipPath': 'url(#clip1_828_47067)' }, _createElement('path', {
                                'd': 'M11.4592 4.368L11.1392 12.032H8.81919L8.49919 4.368H11.4592ZM10.0192 16.128C9.53919 16.128 9.14452 15.9893 8.83519 15.712C8.53652 15.424 8.38719 15.072 8.38719 14.656C8.38719 14.2293 8.53652 13.872 8.83519 13.584C9.14452 13.296 9.53919 13.152 10.0192 13.152C10.4885 13.152 10.8725 13.296 11.1712 13.584C11.4805 13.872 11.6352 14.2293 11.6352 14.656C11.6352 15.072 11.4805 15.424 11.1712 15.712C10.8725 15.9893 10.4885 16.128 10.0192 16.128Z',
                                'fill': 'white'
                            })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_828_47067' }, _createElement('rect', {
                                'width': '10',
                                'height': '10',
                                'fill': 'white',
                                'transform': 'translate(5 5)'
                            })), _createElement('clipPath', { 'id': 'clip1_828_47067' }, _createElement('rect', {
                                'width': '20',
                                'height': '20',
                                'fill': 'white'
                            }))))], '\n          Low Stock\n        ') : null, this.out_of_stock ? _createElement('div', {
                            'className': 'stock-info out-of-stock copy copy--medium',
                            'key': '3212'
                        }, [_createElement('svg', {
                                'width': '20',
                                'height': '20',
                                'viewBox': '0 0 20 20',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'key': '33040'
                            }, _createElement('rect', {
                                'x': '1',
                                'y': '1',
                                'width': '18',
                                'height': '18',
                                'rx': '9',
                                'fill': '#FF0000',
                                'stroke': '#FF0000',
                                'strokeWidth': '2'
                            }), _createElement('g', { 'clipPath': 'url(#clip0_819_52756)' }, _createElement('path', {
                                'd': 'M14.993 5.07788L4.99304 15.0779',
                                'stroke': 'white',
                                'strokeWidth': '2',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round'
                            }), _createElement('path', {
                                'd': 'M4.99304 5.07788L14.993 15.0779',
                                'stroke': 'white',
                                'strokeWidth': '2',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round'
                            })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_819_52756' }, _createElement('rect', {
                                'width': '10',
                                'height': '10',
                                'fill': 'white',
                                'transform': 'translate(5 5)'
                            }))))], '\n          Out of Stock\n        ') : null, this.product_card_note ? _createElement('div', {
                            'className': 'stock-info card-note copy copy--medium',
                            'key': '4024'
                        }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'fill': 'none',
                                'viewBox': '0 0 20 20',
                                'key': '41180'
                            }, _createElement('rect', {
                                'width': '18',
                                'height': '18',
                                'x': '1',
                                'y': '1',
                                'fill': '#48C11E',
                                'stroke': '#48C11E',
                                'strokeWidth': '2',
                                'rx': '9'
                            }), _createElement('path', {
                                'stroke': '#fff',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round',
                                'strokeWidth': '2',
                                'd': 'm6.155 10.585 2.354 2.353 6.015-6.015'
                            }))], '\n          ', this.product_card_note, '\n        ') : null, _createElement('div', { 'className': 'price-container' }, _createElement('span', { 'className': 'price h5' + (this.on_sale ? ' on-sale' : '') }, this.formatPrice(this.price)), _createElement('span', { 'className': 'compare-at copy copy--small' }, this.formatPrice(this.compare_at_price)))))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'card cm_SearchResult' }, _createElement.apply(this, [
                'div',
                {
                    'className': 'js-grid' + (this.view ? ` ${ this.view }` : '') + ' cmRepeater_items',
                    'data-grid-small': '2',
                    'data-grid-large': '4'
                },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '7050'
                        }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '10350'
                        }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1568'
                    }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1982'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '2236'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2554'
                    }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t contain any\n    ',
                        !!this.vehicleString && !this.notFilteredByVehicle ? [
                            '\n      products that fit a ',
                            _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                        '\n    Click ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '32301'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3749'
                    }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3968'
                    }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '4212'
                    }, 'Your search request failed to process\n', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '43630' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '44500' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '45330' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '43284' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-default btn-inverse cm_button cm_button__primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, '\n        LOAD MORE\n      ');
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'cm_button cm_button__primary cm_dialogButton' }, '\n  Filters ', [_createElement('svg', {
                                    'width': '15',
                                    'height': '9',
                                    'viewBox': '0 0 15 9',
                                    'fill': 'none',
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'key': '690'
                                }, _createElement('path', {
                                    'd': 'M13.25 1.5L7.625 7.5L2 1.5',
                                    'stroke': '#FF6800',
                                    'strokeWidth': '3',
                                    'strokeLinecap': 'round',
                                    'strokeLinejoin': 'round'
                                }))]);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', {
                'className': 'cm_total-hits',
                'role': 'status'
            }, _createElement('span', {}, this.totalHits, ' results')), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'SORT BY ', [_createElement('svg', {
                    'width': '15',
                    'height': '9',
                    'viewBox': '0 0 15 9',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'key': '12110'
                }, _createElement('path', {
                    'd': 'M13.25 1.5L7.625 7.5L2 1.5',
                    'stroke': '#FF6800',
                    'strokeWidth': '3',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round'
                }))]), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })]);
}
        export const componentNames = ["cm:message","cm:customMessage","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:loadMoreButton","cm:loadMore","cm:SearchResult","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader"]